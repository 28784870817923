import { Spinner, Message } from '..'
import { AiFillGolden, AiOutlineRadarChart, AiOutlineAppstoreAdd, AiOutlineUserAdd } from "react-icons/ai"
import React, { useState, useEffect } from "react"
import { FaPenAlt, FaTrash, FaSearch, FaBroom, FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import useNetworksHook from '../../api/networks'
import useUsersHook from '../../api/users'
import useTypeNetworksHook from '../../api/type-networks'
import PhoneInput from 'react-phone-number-input'
import * as Yup from "yup"
import "yup-phone"


var FormNetworks = ({
  id,
  edit,
  members,
  branchId,
  subNetwork,
  formCleanHandler,
  isLoading,
  isError,
  handleSubmit,
  submitHandler,
  error,
  isSuperAdmin,
}) => {

  const { updateNetwork } = useNetworksHook({ page: 1, q: '' })
  const { getTypeNetworks } = useTypeNetworksHook({})
  const { isLoading: isLoadingMember, error: errorUpdate, mutateAsync: mutateAsyncUpdateNetwork } = updateNetwork
  const { postUserByInput } = useUsersHook({ limit: 100000, page: 1 })
  const { mutateAsync: mutateAsyncPostSearchUser } = postUserByInput
  const { data: dataTypeNetworks } = getTypeNetworks

  // Inicio - Network

  const [idNetwork, setIdNetwork] = useState('')
  const [nameNetwork, setNameNetwork] = useState('')
  const [typeNetwork, setTypeNetwork] = useState('')
  const [maxNumberUsersXNetwork, setMaxNumberUsersXNetwork] = useState(0)
  const [isActiveNetwork, setIsActiveNetwork] = useState(false)

  var networks = []


  // Creacion de una red (SubNetwork)
  const addNetwork = async () => {

    setIdNetwork('')
    setNameNetwork('')
    setTypeNetwork('')
    setMaxNumberUsersXNetwork(0)
    setIsActiveNetwork(false)

    document.getElementById('isActiveNetwork').checked = false

    const networkUpdate = await mutateAsyncUpdateNetwork({
      _id: id,
      subNetwork: {
        name: nameNetwork,
        type: typeNetwork,
        maxNumberUsersXNetwork: maxNumberUsersXNetwork,
        active: isActiveNetwork,
      }, update: '0'
    })


    networks.splice(0)

    for (let i = 0; i < networkUpdate.subNetworks.length; i++) {
      networks.push({
        _id: networkUpdate.subNetworks[i]._id,
        name: networkUpdate.subNetworks[i].subNetwork.name,
        type: networkUpdate.subNetworks[i].subNetwork.type,
        maxNumberUsersXNetwork: networkUpdate.subNetworks[i].subNetwork.maxNumberUsersXNetwork,
        members: networkUpdate.subNetworks[i].subNetwork.members,
        active: networkUpdate.subNetworks[i].subNetwork.active,
      })
    }
    setIdNetwork('')
    setNameNetwork('')
    setTypeNetwork('')
    setMaxNumberUsersXNetwork(0)
    setIsActiveNetwork(false)

  }

  // Metodo para editar una red(SubNetwork)
  const editNetwork = async () => {

    if (document.getElementById('isActiveNetwork').checked) {
      setIsActiveNetwork(true)
    } else {
      setIsActiveNetwork(false)
    }

    const networkUpdate = await mutateAsyncUpdateNetwork({
      _id: id,
      subNetwork: {
        _id: idNetwork,
        name: nameNetwork,
        type: typeNetwork,
        maxNumberUsersXNetwork: maxNumberUsersXNetwork,
        active: isActiveNetwork,
      }, update: '0'
    })

    networks.splice(0)

    for (let i = 0; i < networkUpdate.subNetworks.length; i++) {
      networks.push({
        _id: networkUpdate.subNetworks[i].subNetwork._id,
        name: networkUpdate.subNetworks[i].subNetwork.name,
        type: networkUpdate.subNetworks[i].subNetwork.type,
        maxNumberUsersXNetwork: networkUpdate.subNetworks[i].subNetwork.maxNumberUsersXNetwork,
        members: networkUpdate.subNetworks[i].subNetwork.members,
        active: networkUpdate.subNetworks[i].subNetwork.active,
      })
    }

  }

  // Metodo para limpiar el formulario de redes
  /*const cleanNetwork = () => {

    setIdNetwork('')
    setNameNetwork('')
    setTypeNetwork('')
    setMaxNumberUsersXNetwork(0)
    setIsActiveNetwork(false)
    document.getElementById('isActiveNetwork').checked = false
  }*/


  useEffect(() => {

    if (subNetwork != undefined) {

      setIdNetwork(subNetwork._id != undefined ? subNetwork._id : '')
      setNameNetwork(subNetwork.name != undefined ? subNetwork.name : '')
      setTypeNetwork(subNetwork.type != undefined ? subNetwork.type : '')
      setMaxNumberUsersXNetwork(subNetwork.maxNumberUsersXNetwork != undefined ? subNetwork.maxNumberUsersXNetwork : 0)
      setIsActiveNetwork(subNetwork.active != undefined ? subNetwork.active : false)
      if (subNetwork.active)
        document.getElementById("isActiveNetwork").checked = true
      else
        document.getElementById("isActiveNetwork").checked = false
    }
  }, [subNetwork])

  // Fin - Network



  // Inicio - Member
  const [idMember, setIdMember] = useState('')
  const [typeIdentificationMember, setTypeIdentificationMember] = useState(undefined)
  const [identificationMember, setIdentificationMember] = useState('')
  const [nameMember, setNameMember] = useState('')
  const [lastNameMember, setLastNameMember] = useState('')
  const [emailMember, setEmailMember] = useState('')
  const [phoneNumberMember, setPhoneNumberMember] = useState()
  const [sexMember, setSexMember] = useState('')
  const [birthdayMember, setBirthdayMember] = useState('')
  const [editMember, setEditMember] = useState(false)
  const [inputSearchMember, setInputSearchMember] = useState('')
  const [isActiveMember, setIsActiveMember] = useState(true)
  const [isDisabledMember, setIsDisabledMember] = useState(true)
  const [isDisabledMemberEdit, setIsDisabledMemberEdit] = useState(true)
  const [errorsListMember, setErrorsListMember] = useState('')


  const cleanMember = () => {

    setInputSearchMember('')
    setIsDisabledMember(true)
    setIsDisabledMemberEdit(true)
    setIdMember('')
    setTypeIdentificationMember('')
    setIdentificationMember('')
    setBirthdayMember('')
    setSexMember('')
    setNameMember('')
    setLastNameMember('')
    setEmailMember('')
    setPhoneNumberMember('')
    setIsActiveMember(false)
    setEditMember(false)
    setErrorsListMember('')
    document.getElementById('isActiveMember').removeAttribute('checked')
  }

  const addMember = async () => {

    let formatDateBirthday = ''
    // Validacion de la fecha por conversion del componente web
    if (birthdayMember != '') {
      let dateArray = birthdayMember.split('-')
      formatDateBirthday = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`
    }

    const networkUpdate = await mutateAsyncUpdateNetwork({
      _id: id,
      subNetwork: {
        _id: subNetwork,
      },
      branch_id: branchId,
      members: {
        _id: idMember,
        typeIdentification: typeIdentificationMember,
        identification: identificationMember,
        birthday: formatDateBirthday,
        sex: sexMember,
        name: nameMember,
        lastname: lastNameMember,
        email: emailMember,
        phone: phoneNumberMember,
        role: 'USER_MEMBER',
      },
      update: '1'
    })

    members.splice(0)

    for (let i = 0; i < networkUpdate.subNetworks.members.length; i++) {

      members.push({
        _id: networkUpdate.subNetworks.members[i].user._id,
        typeIdentification: networkUpdate.subNetworks.members[i].user.typeIdentification,
        identification: networkUpdate.subNetworks.members[i].user.identification,
        birthday: networkUpdate.subNetworks.members[i].user.birthday,
        sex: networkUpdate.subNetworks.members[i].user.sex,
        name: networkUpdate.subNetworks.members[i].user.name,
        lastname: networkUpdate.subNetworks.members[i].user.lastname,
        email: networkUpdate.subNetworks.members[i].user.email,
        phone: networkUpdate.subNetworks.members[i].user.phone,
        active: networkUpdate.subNetworks.members[i].active,
      })

    }
    cleanMember()
    document.getElementById("close-network-member").click()
  }

  const editMemberFunction = (idMember) => {
    setEditMember(true)
    setIsDisabledMember(true)
    setIsDisabledMemberEdit(false)
    var memberFound = members.find((e) => e._id == idMember)

    setIdMember(memberFound._id)
    setTypeIdentificationMember(memberFound.typeIdentification)
    setIdentificationMember(memberFound.identification)
    setBirthdayMember(memberFound.birthday != undefined ? memberFound.birthday.substring(0, 10) : '')
    setSexMember(memberFound.sex)
    setNameMember(memberFound.name)
    setLastNameMember(memberFound.lastname)
    setEmailMember(memberFound.email)
    setPhoneNumberMember(memberFound.phone)
    setIsActiveMember(memberFound.active)
    if (memberFound.active)
      document.getElementById("isActiveMember").checked = true
    else
      document.getElementById("isActiveMember").checked = false
  }

  const schemaSearchMember = Yup.object().shape({
    search: Yup.string().required("* Ingrese un correo electrónico, un número telefónico o una identificación"),
  })

  const deleteMemberFunction = async (idMember) => {

    const networkUpdate = await mutateAsyncUpdateNetwork({
      _id: id,
      subNetwork: {
        _id: subNetwork,
      },
      members: {
        _id: idMember,
      }, update: '3'
    })

    if (networkUpdate.members.length > 1) {
      members.splice(0)
    }

    for (let i = 0; i < networkUpdate.members.length; i++) {
      members.push({
        _id: networkUpdate.subNetworks.members[i].user._id,
        typeIdentification: networkUpdate.subNetworks.members[i].user.typeIdentification,
        identification: networkUpdate.subNetworks.members[i].user.identification,
        birthday: networkUpdate.subNetworks.members[i].user.birthday,
        sex: networkUpdate.subNetworks.members[i].user.sex,
        name: networkUpdate.subNetworks.members[i].user.name,
        lastname: networkUpdate.subNetworks.members[i].user.lastname,
        email: networkUpdate.subNetworks.members[i].user.email,
        phone: networkUpdate.subNetworks.members[i].user.phone,
        active: networkUpdate.subNetworks.members[i].active,
      })
    }
  }

  const searchMember = async () => {

    const retorno = await mutateAsyncPostSearchUser({ input: inputSearchMember })
    var errorMessage = ''
    cleanMember()

    if (retorno._id == undefined) {
      errorMessage = '* No se ha encontrado ningún usuario. Ingrese un nuevo usuario'
      setErrorsListMember(errorMessage)
      setEditMember(false)
      setIsDisabledMember(false)
      setIsDisabledMemberEdit(false)
    } else {
      setEditMember(false)
      setIsDisabledMember(true)
      setIsDisabledMemberEdit(false)
    }

    setIdMember(retorno && retorno._id != undefined ? retorno._id : '')
    setTypeIdentificationMember(retorno && retorno.typeIdentification != undefined ? retorno.typeIdentification : '')
    setIdentificationMember(retorno && retorno.identification != undefined ? retorno.identification : '')
    setBirthdayMember(retorno && retorno.birthday != undefined ? retorno.birthday.substring(0, 10) : '')
    setSexMember(retorno && retorno.sex != undefined ? retorno.sex : '')
    setNameMember(retorno && retorno.name != undefined ? retorno.name : '')
    setLastNameMember(retorno && retorno.lastname != undefined ? retorno.lastname : '')
    setEmailMember(retorno && retorno.email != undefined ? retorno.email : '')
    setPhoneNumberMember(retorno && retorno.phone != undefined ? retorno.phone : '')
    setIsActiveMember(retorno && retorno.active != undefined ? retorno.active : false)
  }


  const formValidationSearchMember = async () => {
    let validacion = undefined
    try {

      validacion = await schemaSearchMember.validate({ search: inputSearchMember })
    }
    catch (errors) {
      setErrorsListMember(errors.message)
    }

    if (validacion !== '') {
      searchMember()
    }
  }

  // Mostrar el nombre del tipo de red por medio del Id
  const showNameTypeNetwork = (typeNetworkId) => {

    if (typeNetworkId != undefined && typeNetworkId != '') {
      var typeNetworkFound = dataTypeNetworks.data.find((e) => e._id == typeNetworkId)
      if (typeNetworkFound != undefined) {
        return typeNetworkFound.name
      } else {
        return ''
      }

    }
  }
  // Fin - Member


  return (
    <div
      className='modal fade'
      id='networkModal'
      tabIndex='-1'
      aria-labelledby='networkModalLabel'
      aria-hidden='true'
    >
      {errorUpdate ? <Message variant='danger'>{errorUpdate}</Message> : ''}
      <div className='modal-dialog modal-dialog-grande'>
        <div className='modal-content modal-background'>
          <div className='modal-header'>
            <h3 className='modal-title ' id='networkModalLabel'>
              {edit ? 'Editar Red' : 'Agregar Red'}
            </h3>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={() => formCleanHandler()}
            ></button>
          </div>
          <div className='modal-body'>


            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item" role="presentation">
                <a className="nav-link active" id="icon-tab-0" data-bs-toggle="tab" href="#icon-tabpanel-0" role="tab" aria-controls="icon-tabpanel-0" aria-selected="true"><AiOutlineRadarChart /> Datos de la Red</a>
              </li>
              <li className="nav-item" role="presentation" style={{ display: edit === true ? 'block' : 'none' }}>
                <a className="nav-link" id="icon-tab-1" data-bs-toggle="tab" href="#icon-tabpanel-1" role="tab" aria-controls="icon-tabpanel-1" aria-selected="false"><AiOutlineAppstoreAdd /> Usuarios dentro de su Red </a>
              </li>
            </ul>
            <div className="tab-content pt-3" id="tab-content">
              <div className="tab-pane active" id="icon-tabpanel-0" role="tabpanel" aria-labelledby="icon-tab-0">
                {isLoading ? (
                  <Spinner />
                ) : isError ? (
                  <Message variant='danger'>{error}</Message>
                ) : (
                  <form onSubmit={handleSubmit(submitHandler)}>

                    <div className='row'>

                      <div className="col-md-6">
                        <label htmlFor="nameNetwork"><b>Nombres:</b></label>
                        {isSuperAdmin ? (<input
                          type='text'
                          name='nameNetwork'
                          value={nameNetwork}
                          onChange={(event) => setNameNetwork(event.target.value)}
                          className='form-control'
                        />) : <label className='form-control'>{nameNetwork}</label>}

                      </div>
                      <div className="col-md-6">
                        <label htmlFor="typeNetwork"><b>Tipo de Red:</b></label>

                        {isSuperAdmin ? (<select onChange={(event) => setTypeNetwork(event.target.value)}
                          id='typeNetwork' name='typeNetwork' className='form-control' value={typeNetwork}>
                          <option value="" >Seleccione una opción</option>
                          {dataTypeNetworks && dataTypeNetworks.data.map((typeNetwork) => (
                            typeNetwork.origin == 'user_member' ?
                              <option key={typeNetwork._id} value={typeNetwork._id} >{typeNetwork.name}</option> : ''

                          ))}
                        </select>) : <label className='form-control'>{showNameTypeNetwork(typeNetwork)}</label>}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="maxNumberUsersXNetwork"><b># Máximo Usuarios Por Red:</b></label>
                        {isSuperAdmin ? (<input
                          type='number'
                          name='maxNumberUsersXNetwork'
                          value={maxNumberUsersXNetwork}
                          onChange={(event) => setMaxNumberUsersXNetwork(event.target.value)}
                          className='form-control'
                        />) : <label className='form-control' >{maxNumberUsersXNetwork}</label>}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="isActiveNetwork"><b>Estado:</b></label>
                        <div className='form-check form-switch form-control'>
                          <input className='form-check-input'
                            type='checkbox'
                            id='isActiveNetwork'
                            name='isActiveNetwork'
                            value={isActiveNetwork}
                            onChange={(event) => setIsActiveNetwork(event.target.checked)}
                          />
                          <label className='form-check-label' htmlFor='isActiveMember'>Activo</label>
                        </div>
                      </div>

                      <div className='row'>&nbsp;</div>
                      <div className='row'>
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                          {isSuperAdmin ? (
                            <button type='button' onClick={edit ? editNetwork : addNetwork} data-bs-dismiss='modal' className='btn btn-primary form-control' >
                              <AiFillGolden /> &nbsp; {edit ? 'Actualizar' : 'Crear '}
                            </button>) : ''}
                        </div>
                        <div className="col-md-4"></div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
              <div className="tab-pane" id="icon-tabpanel-1" role="tabpanel" aria-labelledby="icon-tab-1">
                <center>
                  {isActiveNetwork ?
                    <a className="btn btn-primary" href="#open-modal-member-network" onClick={() => cleanMember()}> <AiOutlineUserAdd /> &nbsp;Agregar un Usuario</a> : ''
                  }
                </center>
                <br></br>
                <table className='table * table-sm table-border'>
                  <thead className='border-0'>
                    <tr className='text-center'>
                      <th>Nombre del Usuario</th>
                      <th>Estado</th>
                      <th>Identificación</th>
                      <th>Número Celular</th>
                      <th>Email</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>

                    {members.map((listValue, index) => {
                      return (
                        <tr key={index} className='text-center'>
                          <td>{listValue.name}&nbsp;{listValue.lastname}</td>
                          <td>
                            {listValue.active ? (
                              <FaCheckCircle className='text-success' />
                            ) : (
                              <FaTimesCircle className='text-danger' />
                            )}
                          </td>
                          <td>{listValue.identification}</td>
                          <td>{listValue.phone}</td>
                          <td>{listValue.email}</td>
                          <td>
                            <div className='btn-group'>

                              <a className="btn btn-primary btn-sm rounded-pill" href="#open-modal-member-network" onClick={() => editMemberFunction(listValue._id)}> <FaPenAlt /></a>
                              &nbsp;
                              {isActiveNetwork ?
                                <button className='btn btn-danger btn-sm ms-1 rounded-pill' onClick={() => { if (window.confirm('Desea eliminar el miembro de la Red ?')) deleteMemberFunction(listValue._id) }}>
                                  <span><FaTrash /></span>
                                </button> : ''
                              }
                            </div>
                          </td>
                        </tr>)
                    })}
                  </tbody>
                </table>
                <div id="open-modal-member-network" className="modal-window-external" style={{ top: '10px' }}>
                  <div>
                    <b><h5 >
                      {editMember ? 'Editar Abonado Secundario' : 'Agregar Abonado Secundario'}
                    </h5></b>
                    <a href="#" id="close-network-member" title="Close" className="modal-close">&#x2715;</a>
                    <hr></hr>
                    <div className='row'>
                      <div className="col-md-12">

                        {editMember == false ? (
                          <div className='row'>
                            <div className="row">
                              <div className="col-md-2">
                                <label><b>Búsqueda:</b></label>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type='text'
                                  name='inputSearchMember'
                                  placeholder='Usuarios por identificación, email o teléfono'
                                  value={inputSearchMember}
                                  style={({ width: '100%' })}
                                  onChange={(event) => setInputSearchMember(event.target.value)}
                                />
                              </div>
                              <div className="col-md-2">
                                <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip" title="Buscar">
                                  <button type='button' className='btn btn-primary btn-sm rounded-pill'
                                    onClick={() => formValidationSearchMember()}>
                                    <FaSearch />
                                  </button>
                                </span>
                                &nbsp;
                                <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip" title="Limpiar">
                                  <button className='btn btn-primary btn-sm rounded-pill'
                                    onClick=
                                    {() => cleanMember()}>
                                    <FaBroom />
                                  </button>
                                </span>
                              </div>
                            </div>
                            &nbsp;
                            <div className='row text-center'>
                              {errorsListMember ? (<span className='text-danger'>{errorsListMember}</span>) : ''}
                            </div>
                            <br></br>
                            <hr></hr>
                          </div>

                        ) : ''}

                      </div>
                    </div>

                    <div className='row'>
                      {editMember == false ? (
                        <div className="col-md-6">
                          <label htmlFor="typeIdentificationMember"><b>Tipo Identificación:</b></label>
                          <select onChange={(event) => setTypeIdentificationMember(event.target.value)} disabled={isDisabledMember}
                            name='typeIdentificationMember' className='form-control' value={typeIdentificationMember}>
                            <option key="" >Seleccione una opción</option>
                            <option key="RUC" >RUC</option>
                            <option key="Cedula" >Cedula</option>
                            <option key="Pasaporte" >Pasaporte</option>
                          </select>
                        </div>) : (
                        <div className="col-md-6">
                          <label><b>Tipo Identificación:</b></label><br></br>
                          <label>{typeIdentificationMember == '' ? 'No ha ingresado' : typeIdentificationMember}</label>
                          <br></br>
                        </div>)}

                      {editMember == false ? (
                        <div className="col-md-6">
                          <label htmlFor="identificationMember"><b>Identificación:</b></label>
                          <input
                            type='text'
                            name='identificationMember'
                            value={identificationMember}
                            onChange={(event) => setIdentificationMember(event.target.value)}
                            className='form-control'
                            disabled={isDisabledMember}
                          />
                        </div>) : (
                        <div className="col-md-6">
                          <label><b>Identificación:</b></label><br></br>
                          <label>{identificationMember == '' ? 'No ha ingresado' : identificationMember}</label>
                          <br></br>
                        </div>)}

                      {editMember == false ? (
                        <div className="col-md-6">
                          <label htmlFor="nameMember"><b>Nombres:</b></label>
                          <input
                            type='text'
                            name='nameMember'
                            value={nameMember}
                            onChange={(event) => setNameMember(event.target.value)}
                            className='form-control'
                            disabled={isDisabledMember}
                          />
                        </div>) : (
                        <div className="col-md-6">
                          <label><b>Nombres:</b></label><br></br>
                          <label>{nameMember == '' ? 'No ha ingresado' : nameMember}</label>
                          <br></br>
                        </div>)}

                      {editMember == false ? (
                        <div className="col-md-6">
                          <label htmlFor="lastNameMember"><b>Apellidos:</b></label>
                          <input
                            type='text'
                            name='lastNameMember'
                            value={lastNameMember}
                            onChange={(event) => setLastNameMember(event.target.value)}
                            className='form-control'
                            disabled={isDisabledMember}
                          />
                        </div>) : (
                        <div className="col-md-6">
                          <label><b>Apellidos:</b></label><br></br>
                          <label>{lastNameMember == '' ? 'No ha ingresado' : lastNameMember}</label>
                          <br></br>
                        </div>)}

                      {editMember == false ? (
                        <div className="col-md-6">
                          <label htmlFor="emailMember"><b>Correo Electrónico:</b></label>
                          <input
                            type='email'
                            name='emailMember'
                            value={emailMember}
                            onChange={(event) => setEmailMember(event.target.value)}
                            className='form-control'
                            disabled={isDisabledMember}
                          />
                        </div>) : (
                        <div className="col-md-6">
                          <label><b>Correo Electrónico:</b></label><br></br>
                          <label>{emailMember == '' ? 'No ha ingresado' : emailMember}</label>
                          <br></br>
                        </div>)}

                      {editMember == false ? (
                        <div className="col-md-6">
                          <label htmlFor="phoneNumberMember"><b>Teléfono:</b></label>
                          <PhoneInput
                            name="phoneNumberMember"
                            placeholder="Ingrese un número telefónico"
                            value={phoneNumberMember}
                            onChange={setPhoneNumberMember}
                            className='form-control'
                            disabled={isDisabledMember}
                          />
                        </div>) : (
                        <div className="col-md-6">
                          <label><b>Teléfono:</b></label><br></br>
                          <label>{phoneNumberMember == '' ? 'No ha ingresado' : phoneNumberMember}</label>
                          <br></br>
                        </div>)}

                      {editMember == false ? (
                        <div className="col-md-6">
                          <label htmlFor="sexMember"><b>Sexo:</b></label>
                          <select onChange={(event) => setSexMember(event.target.value)} disabled={isDisabledMember}
                            name='sexMember' className='form-control' value={sexMember}>
                            <option key="" >Seleccione una opción</option>
                            <option key="Masculino" >Masculino</option>
                            <option key="Femenino" >Femenino</option>
                          </select>
                        </div>) : (
                        <div className="col-md-6">
                          <label><b>Sexo:</b></label><br></br>
                          <label>{sexMember == '' ? 'No ha ingresado' : sexMember}</label>
                          <br></br>
                        </div>)}

                      {editMember == false ? (
                        <div className="col-md-6">
                          <label htmlFor="birthdayMember"><b>F. Nacimiento:</b></label>
                          <input
                            type='date'
                            name='birthdayMember'
                            value={birthdayMember}
                            onChange={(event) => setBirthdayMember(event.target.value)}
                            className='form-control'
                            disabled={isDisabledMember}
                          />
                        </div>) : (
                        <div className="col-md-6">
                          <label><b>F. Nacimiento:</b></label><br></br>
                          <label>{birthdayMember == '' ? 'No ha ingresado' : birthdayMember}</label>
                          <br></br>
                        </div>)}
                    </div>
                    
                    {editMember ? (<hr></hr>) : ''}

                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="isActiveMember"><b>Estado:</b></label>
                        <div className='form-check form-switch form-control'>
                          <input className='form-check-input'
                            type='checkbox'
                            id='isActiveMember'
                            name='isActiveMember'
                            value={isActiveMember}
                            onChange={(event) => setIsActiveMember(event.target.checked)}
                            disabled={isDisabledMemberEdit}
                          />
                          <label className='form-check-label' htmlFor='isActiveMember'>
                            Activo
                          </label>
                        </div>
                      </div>

                      <div className='row'>&nbsp;</div>
                      <div className='row'>
                        <div className="col-md-4">
                          <p style={({ display: 'none' })}>{idMember}</p>
                        </div>
                        <div className="col-md-4">
                          <button type='button' onClick={() => addMember()} className='btn btn-primary form-control' disabled={isDisabledMemberEdit} >
                            <AiOutlineUserAdd /> &nbsp;

                            {isLoadingMember ? (
                              <span className='spinner-border spinner-border-sm' />
                            ) : (
                              ''
                            )} &nbsp;
                            {editMember ? 'Actualizar' : 'Crear '}
                          </button>
                        </div>
                        <div className="col-md-4">

                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormNetworks
